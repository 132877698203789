import { render, staticRenderFns } from "./addForm.vue?vue&type=template&id=77129cb6&scoped=true&"
import script from "./addForm.vue?vue&type=script&lang=js&"
export * from "./addForm.vue?vue&type=script&lang=js&"
import style0 from "./addForm.vue?vue&type=style&index=0&id=77129cb6&prod&scoped=true&lang=css&"
import style1 from "./addForm.vue?vue&type=style&index=1&id=77129cb6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77129cb6",
  null
  
)

export default component.exports